// TherapistDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Global or shared CSS
import '../styles/main.css';
// Therapist-specific CSS
import '../styles/therapistdashboard.css';

function unifyId(obj) {
    if (!obj) return;
    if (obj._id && typeof obj._id === 'object' && obj._id.$oid) {
        obj._id = obj._id.$oid;
    }
}

function unifyAvailability(arr) {
    arr.forEach((slot) => {
        if (slot._id && typeof slot._id === 'object' && slot._id.$oid) {
            slot._id = slot._id.$oid;
        }
        if (slot.bookedBy) {
            unifyId(slot.bookedBy);
        }
    });
}

export default function TherapistDashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [availability, setAvailability] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    // For new availability
    const [selectedDate, setSelectedDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    // Profile fields
    const [description, setDescription] = useState('');
    const [editingDescription, setEditingDescription] = useState(false);
    const [selectedComms, setSelectedComms] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [address, setAddress] = useState('');

    // Bookings
    const [myBookings, setMyBookings] = useState([]);
    const [tempMessage, setTempMessage] = useState('');

    // Active tab state: "bookings", "availability", or "profile"
    const [activeTab, setActiveTab] = useState("bookings");

    // Check user on mount
    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((r) => r.json())
            .then((data) => {
                if (data.isAuthenticated && data.user.therapyrole === 'therapist') {
                    unifyId(data.user);
                    setUser(data.user);

                    // Fill some fields
                    setDescription(data.user.description || '');
                    setSelectedComms(data.user.communicationMethods || []);
                    setLanguages(data.user.languages || []);
                    setSpecializations(data.user.specializations || []);
                    setAddress(data.user.address || '');

                    // Load availability & bookings
                    fetchAvailability(data.user._id);
                    fetchMyBookings(data.user._id);
                } else {
                    navigate('/');
                }
            })
            .catch(() => setErrorMessage('Auth check failed'));
    }, [navigate]);

    // Load availability
    function fetchAvailability(therapistId) {
        fetch(`/api/therapist/${therapistId}/availability`, { credentials: 'include' })
            .then((r) => {
                if (!r.ok) throw new Error('Failed to fetch availability');
                return r.json();
            })
            .then((data) => {
                unifyAvailability(data);
                setAvailability(data);
            })
            .catch((e) => setErrorMessage(e.message));
    }

    // Load bookings
    function fetchMyBookings(therapistId) {
        fetch(`/api/book/therapist/${therapistId}`, { credentials: 'include' })
            .then((r) => r.json())
            .then((data) => {
                if (!data.success) throw new Error(data.message);
                data.bookings.forEach((b) => {
                    if (b.clientId) unifyId(b.clientId);
                });
                setMyBookings(data.bookings);
            })
            .catch((e) => setErrorMessage(e.message));
    }

    // Add new slot
    async function handleAvailabilitySubmit() {
        if (!selectedDate || !startTime || !endTime) {
            setErrorMessage('Missing date/time');
            return;
        }
        try {
            const res = await fetch(`/api/therapist/${user._id}/availability`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ day: selectedDate, startTime, endTime }),
            });
            const data = await res.json();
            if (!res.ok) throw new Error(data.message || 'Failed to add slot');
            unifyAvailability(data.availability);
            setAvailability(data.availability);

            // Reset fields
            setSelectedDate('');
            setStartTime('');
            setEndTime('');
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    // (Video session functionality removed due to technical limitations)
    // function joinSession(token) {
    //     window.location.href = `/video-session?link=${encodeURIComponent(token)}`;
    // }

    // Cancel booking
    async function cancelBooking(sessionId) {
        try {
            const res = await fetch(`/api/book/${sessionId}/cancel`, {
                method: 'PUT',
                credentials: 'include',
            });
            const data = await res.json();
            if (!data.success) throw new Error(data.message);

            // Reload data
            fetchMyBookings(user._id);
            fetchAvailability(user._id);
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    // Update profile
    async function handleProfileUpdate() {
        try {
            const res = await fetch(`/api/therapist/${user._id}/profile`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    description,
                    communicationMethods: selectedComms,
                    languages,
                    specializations,
                    address,
                }),
            });
            const data = await res.json();
            if (!res.ok) throw new Error(data.message || 'Profile update error');
            alert('Profile updated');
            setEditingDescription(false);
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    // Toggle communication method
    function toggleComm(method) {
        if (selectedComms.includes(method)) {
            setSelectedComms(selectedComms.filter((m) => m !== method));
        } else {
            setSelectedComms([...selectedComms, method]);
        }
    }

    // Example for sending a message (not fully implemented)
    function sendMessage(slotId) {
        console.log(`Sending message: "${tempMessage}" to slot: ${slotId}`);
        setTempMessage('');
    }

    return (
        <div className="dashboard-container">
            {/* Top header */}
            <header>
                <h1>Welcome, {user?.displayName || 'Therapist'}</h1>
                <button onClick={() => (window.location.href = '/auth/logout')}>
                    Logout
                </button>
            </header>

            {/* Error display */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {/* Tab navigation */}
            <div className="tab-navigation">
                <button
                    className={activeTab === "bookings" ? "active" : ""}
                    onClick={() => setActiveTab("bookings")}
                >
                    Manage Bookings
                </button>
                <button
                    className={activeTab === "availability" ? "active" : ""}
                    onClick={() => setActiveTab("availability")}
                >
                    Manage Availability
                </button>
                <button
                    className={activeTab === "profile" ? "active" : ""}
                    onClick={() => setActiveTab("profile")}
                >
                    Edit Profile
                </button>
            </div>

            {/* Conditionally rendered sections */}
            {activeTab === "bookings" && (
                <section className="bookings-section">
                    <h2>My Upcoming Sessions</h2>
                    {myBookings.length === 0 && <p>No upcoming sessions found.</p>}
                    {myBookings.map((b) => (
                        <div key={b._id} className="booking-item">
                            <p>
                                <strong>Time:</strong> {b.time}
                            </p>
                            <p>
                                <strong>Client:</strong> {b.clientId?.displayName || 'Unknown'}
                            </p>
                            <p>
                                <strong>Status:</strong> {b.status}
                            </p>
                            {b.meetingToken && (
                                <div className="meeting-info">
                                    <p>Meeting link will be mail to you</p>
                                </div>
                            )}
                            {b.status === 'upcoming' && (
                                <button onClick={() => cancelBooking(b._id)}>Cancel</button>
                            )}
                        </div>
                    ))}
                </section>
            )}

            {activeTab === "availability" && (
                <section className="availability-section">
                    <h2>Manage Availability</h2>
                    <div className="availability-inputs">
                        <div>
                            <label>Date:</label>
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Start:</label>
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>End:</label>
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                            />
                        </div>
                        <button onClick={handleAvailabilitySubmit}>Add Slot</button>
                    </div>

                    <ul className="availability-list">
                        {availability.map((slot) => (
                            <li key={slot._id} className="availability-card">
                                <strong>
                                    {slot.day} {slot.startTime} - {slot.endTime}
                                </strong>

                                {slot.isBooked ? (
                                    <>
                                        <span className="booked-label">
                                            (Booked by {slot.bookedBy?.displayName || 'someone'})
                                        </span>
                                        {slot.meetingToken && (
                                            <div className="meeting-info">
                                                <p>Meeting link will be mail to you</p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span className="free-label">(Free)</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </section>
            )}

            {activeTab === "profile" && (
                <section className="profile-section">
                    <h2>Edit Profile</h2>
                    <label>Description:</label>
                    <div className="profile-description">
                        {editingDescription ? (
                            <textarea
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        ) : (
                            <p>{description || 'No description provided.'}</p>
                        )}
                        <button
                            className="edit-icon"
                            onClick={() => setEditingDescription(!editingDescription)}
                            title="Edit Description"
                        >
                            {editingDescription ? '✔️' : '✏️'}
                        </button>
                    </div>

                    <label>Communication Methods:</label>
                    <div className="checkbox-group">
                        {['Inperson', 'Virtual'].map((m) => (
                            <label key={m}>
                                <input
                                    type="checkbox"
                                    checked={selectedComms.includes(m)}
                                    onChange={() => toggleComm(m)}
                                />
                                {m}
                            </label>
                        ))}
                    </div>

                    {selectedComms.includes('Inperson') && (
                        <>
                            <label>Address:</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </>
                    )}

                    <label>Languages (comma-separated):</label>
                    <input
                        type="text"
                        value={languages.join(', ')}
                        onChange={(e) =>
                            setLanguages(e.target.value.split(',').map((x) => x.trim()))
                        }
                    />

                    <label>Specializations (comma-separated):</label>
                    <input
                        type="text"
                        value={specializations.join(', ')}
                        onChange={(e) =>
                            setSpecializations(e.target.value.split(',').map((x) => x.trim()))
                        }
                    />

                    <button onClick={handleProfileUpdate}>Update Profile</button>
                </section>
            )}
        </div>
    );
}
