import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';

import ChatPage from './pages/ChatPage';
import ResourcesPage from './pages/ResourcesPage';
import MeditationPage from './pages/MeditationPage';
import JournalingPage from './pages/JournalingPage';
import MoodTrackerPage from './pages/MoodTrackerPage';
import ForumPage from './pages/ForumPage';
import BookingPage from './pages/BookingPage';
import EmergencyHelpPage from './pages/EmergencyHelpPage';
import GamePage from './pages/GamePage';
import Helpseeker from './pages/helpseeker';
import Volunteer from './pages/volunteer';
import Chat from './components/Chat';
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminPanel from './pages/AdminPanel';
import TherapyAdmin from './pages/TherapyAdmin'
import TherapistDashboard from './pages/TherapistDashboard';
import WellnessSeekerDashboard from './pages/WellnessSeekerDashboard';
import VideoCallTherapy from './functions/VideoCallTherapy';
import TelephonicTherapy from './functions/TelephonicTherapy';
import InPersonTherapy from './functions/InPersonTherapy';
import HomeTherapy from './functions/HomeTherapy';
import Chatai from './pages/ChatAI'
import AssessmentLanding from './pages/AssessmentLanding';
import DepressionAnxietyTest from './pages/assessment/DepressionAnxietyTest';
import PersonalityTest from './pages/assessment/PersonalityTest';
import DisorderTest from './pages/assessment/DisorderTest';
import VideoSession from './pages/VideoSession';
import AboutUs from './pages/AboutUs'; 
// WebSocket connection
const socket = io('http://localhost:3000'); // Replace with your backend URL

function App() {
    useEffect(() => {
        const username = 'currentUserUsername'; // Replace with the actual logged-in user's username
        socket.emit('register', username);

        // Listen for notifications
        socket.on('notification', (data) => {
            toast.info(`New message from ${data.sender}: ${data.message}`);
        });

        // Cleanup WebSocket connection on component unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <Router>
            <ToastContainer /> {/* Toast notification provider */}
            <Routes>
                <Route path="/" element={<HomePage />} />
               
                <Route path="/chat" element={<ChatPage />} />
                <Route path="/chat/:username" element={<Chat />} />
                <Route path="/resources" element={<ResourcesPage />} />
                <Route path="/meditation" element={<MeditationPage />} />
                <Route path="/journaling" element={<JournalingPage />} /> 
                <Route path="/mood-tracker" element={<MoodTrackerPage />} />
                <Route path="/forum" element={<ForumPage />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/emergency-help" element={<EmergencyHelpPage />} />
                <Route path="/game" element={<GamePage />} />
                <Route path="/helpseeker" element={<Helpseeker />} />
                <Route path="/volunteer" element={<Volunteer />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="TherapyAdmin" element={<TherapyAdmin />} />
                <Route path="/therapist-dashboard" element={<TherapistDashboard />} />
                <Route path="/wellnessseeker-dashboard" element={<WellnessSeekerDashboard />} />
                <Route path="/" element={<WellnessSeekerDashboard />} />
                <Route path="/videocall-therapy" element={<VideoCallTherapy />} />
                <Route path="/telephonic-therapy" element={<TelephonicTherapy />} />
                <Route path="/inperson-therapy" element={<InPersonTherapy />} />
                <Route path="/home-therapy" element={<HomeTherapy />} />
                <Route path="/chatai" element={<Chatai />} />
                <Route path="/AssessmentLanding" element={<AssessmentLanding />} />
                <Route path="/assessment/depression-anxiety" element={<DepressionAnxietyTest />} />
                <Route path="/assessment/personality" element={<PersonalityTest />} />
                <Route path="/assessment/disorder" element={<DisorderTest />} />
                <Route path="/video-session" element={<VideoSession />} />
                <Route path="/about-us" element={<AboutUs />} />
             

            </Routes>
        </Router>
    );
}

export default App;
