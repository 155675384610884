import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import '../styles/main.css';
import '../styles/home.css';

const HomePage = () => {
    const navigate = useNavigate();
    const [darkMode, setDarkMode] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);

    // Fetch saved theme preference
    useEffect(() => {
        const savedTheme = localStorage.getItem('darkMode');
        if (savedTheme === 'enabled') {
            document.body.classList.add('dark-mode');
            setDarkMode(true);
        }
    }, []);

    // Close mobile menu if window resized to desktop size
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setMobileMenuOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Toggle light/dark theme
    const toggleTheme = () => {
        const isDark = !darkMode;
        setDarkMode(isDark);
        document.body.classList.toggle('dark-mode', isDark);
        localStorage.setItem('darkMode', isDark ? 'enabled' : 'disabled');
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        setMobileMenuOpen(prev => !prev);
    };

    // Toggle Services dropdown visibility
    const toggleServicesDropdown = (e) => {
        // Prevent the click from propagating if needed
        setServicesDropdownOpen(prev => !prev);
    };

    // Close dropdown (and mobile menu) when navigating
    const handleNavigation = (path) => {
        navigate(path);
        setMobileMenuOpen(false);
        setServicesDropdownOpen(false);
    };

    // Animation variants for framer-motion
    const fadeInUp = {
        hidden: { opacity: 0, y: 25 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: 'easeOut' },
        },
    };

    return (
        <>
            <Helmet>
                <title>Danezah - Your Path to Inner Peace</title>
                <meta
                    name="description"
                    content="Discover a personalized journey to mental wellness with Danezah. Engage with expert support, interactive tools, and inspirational resources."
                />
                <meta
                    name="keywords"
                    content="mental health, wellness, self-help, therapy, mindfulness, journaling, Danezah"
                />
                <link rel="canonical" href="https://Danezah.com/" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "Danezah",
                        "url": "https://Danezah.com",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://Danezah.com/search?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <motion.div
                className="home-page-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {/* Desktop Header */}
                <header className="navbar">
                    <div className="navbar-left">
                        <button className="hamburger" onClick={toggleMobileMenu}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </button>
                        <h1 className="kit-title">Danezah</h1>
                    </div>
                    <nav className="navbar-menu">
                        <ul>
                            <li onClick={() => handleNavigation('/about-us')}>About Us</li>
                            <li className="services-dropdown" onClick={toggleServicesDropdown}>
                                Services
                                {servicesDropdownOpen && (
                                    <ul className="dropdown-content">
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleNavigation('/AssessmentLanding');
                                            }}
                                        >
                                            Self-Discovery Quiz
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleNavigation('/Chat');
                                            }}
                                        >
                                            Pocket Therapist
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleNavigation('/booking');
                                            }}
                                        >
                                            Schedule a Session
                                        </li>

                                    </ul>
                                )}
                            </li>
                            <li onClick={() => handleNavigation('/resources')}>Resources</li>
                            <li onClick={() => handleNavigation('/contact-us')}>Contact Us</li>
                        </ul>
                    </nav>
                    <div className="navbar-actions">
                        <button onClick={toggleTheme} className="theme-toggle">
                            {darkMode ? 'Light Mode 🌞' : 'Dark Mode 🌙'}
                        </button>
                    </div>
                </header>

                {/* Mobile Menu */}
                <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li onClick={() => handleNavigation('/about-us')}>About Us</li>
                        <li onClick={() => handleNavigation('/AssessmentLanding')}>Self-Discovery Quiz</li>
                        <li onClick={() => handleNavigation('/booking')}>Schedule a Session</li>
                        <li onClick={() => handleNavigation('/resources')}>Resources</li>
                        <li onClick={() => handleNavigation('/contact-us')}>Contact Us</li>
                    </ul>
                </div>

                {/* Hero Section */}
                <motion.section
                    className="hero"
                    variants={fadeInUp}
                    initial="hidden"
                    animate="visible"
                >
                    <h1 className="hero-heading">
                        Embrace Inner Peace <span className="hero-emoji">🌸</span>
                    </h1>
                    <p className="hero-subtitle">
                        Discover your inner strength with personalized mental wellness tools and expert guidance.
                    </p>
                    <button
                        className="button cta-button"
                        onClick={() => handleNavigation('/quiz')}
                    >
                        Begin Your Transformation
                    </button>
                </motion.section>

                {/* Features Section */}
                <motion.section
                    className="features"
                    variants={fadeInUp}
                    initial="hidden"
                    animate="visible"
                >
                    <div className="feature" onClick={() => handleNavigation('/AssessmentLanding')}>
                        <div className="feature-icon">🧠</div>
                        <h2 className="feature-title">Self-Discovery Quiz</h2>
                        <p className="feature-description">
                            Uncover insights about your emotional well-being.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/chat')}>
                        <div className="feature-icon">💬</div>
                        <h2 className="feature-title">Pocket therapist</h2>
                        <p className="feature-description">
                            Chat live with caring mental health professionals.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/journaling')}>
                        <div className="feature-icon">📔</div>
                        <h2 className="feature-title">Reflect & Grow</h2>
                        <p className="feature-description">
                            Track your journey with guided journaling prompts.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/booking')}>
                        <div className="feature-icon">📅</div>
                        <h2 className="feature-title">Schedule a Session</h2>
                        <p className="feature-description">
                            Book time with certified therapists for personalized care.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/GampePage')}>
                        <div className="feature-icon">🛡️</div>
                        <h2 className="feature-title">Coping Strategies</h2>
                        <p className="feature-description">
                            Learn effective techniques to manage stress and anxiety.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/resources')}>
                        <div className="feature-icon">📚</div>
                        <h2 className="feature-title">Inspiration & Tools</h2>
                        <p className="feature-description">
                            Explore curated articles and resources for mental wellness.
                        </p>
                    </div>

                    <div className="feature" onClick={() => handleNavigation('/ai-assistant')}>
                        <div className="feature-icon">🤖</div>
                        <h2 className="feature-title">AI Wellness Assistant</h2>
                        <p className="feature-description">
                            Get instant insights and support from our smart AI companion.
                        </p>
                    </div>
                </motion.section>

                {/* Testimonials Section */}
                <motion.section
                    className="testimonials"
                    variants={fadeInUp}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <h2 className="testimonials-title">Hear from Our Community</h2>
                    <div className="testimonial-item">
                        <p className="testimonial-text">
                            “Danezah has transformed my life. The guided tools and expert chats helped me find balance.”
                        </p>
                        <span className="testimonial-author">— Alex, Empowered User</span>
                    </div>
                    <div className="testimonial-item">
                        <p className="testimonial-text">
                            “I feel supported and understood. The self-discovery quiz gave me clarity and the resources are top-notch.”
                        </p>
                        <span className="testimonial-author">— Jamie, Grateful Member</span>
                    </div>
                </motion.section>

                {/* Sticky Chat Button */}
                <button className="floating-chat" onClick={() => handleNavigation('/chat')}>
                    💬 Talk to a Specialist
                </button>

                {/* Footer */}
                <footer className="footer">
                    <p>© 2024 Danezah Inc. All rights reserved.</p>
                    <p>
                        Need assistance?{' '}
                        <a href="/contact-us" className="footer-link">
                            Contact us
                        </a>
                    </p>
                </footer>
            </motion.div>
        </>
    );
};

export default HomePage;
