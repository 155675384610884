import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/chatregister.css';
import '../styles/main.css';

const ChatPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedRole, setSelectedRole] = useState('helpseeker');
    const [volunteerFormExpanded, setVolunteerFormExpanded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);
                    if (data.user.role && data.user.username) {
                        if (data.user.role === 'helpseeker') {
                            navigate('/helpseeker');
                        } else if (data.user.role === 'volunteer' && data.user.volunteerApproved) {
                            navigate('/volunteer');
                        }
                    } else {
                        setShowRoleSelection(true);
                    }
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch((err) => {
                console.error('Error checking authentication:', err);
                setErrorMessage('We had trouble loading your information. Please try again.');
                setShowRoleSelection(true);
            });
    }, [navigate]);

    const handleSignIn = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const handleVolunteerCTA = () => {
        setSelectedRole('volunteer');
        setVolunteerFormExpanded(true);
    };

    const handleSubmit = () => {
        if (!fullName.trim()) {
            setErrorMessage('Please enter your full name.');
            return;
        }

        if (selectedRole === 'volunteer' && !phone.trim()) {
            setErrorMessage('Phone number is required for volunteers.');
            return;
        }

        const userDetails = {
            username: fullName,
            role: selectedRole,
            phone: selectedRole === 'volunteer' ? phone : null,
        };

        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userDetails),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('role', selectedRole);
                    localStorage.setItem('username', fullName);
                    if (selectedRole === 'helpseeker') {
                        navigate('/helpseeker');
                    } else if (selectedRole === 'volunteer') {
                        setErrorMessage('Thank you for your interest. Your application is under review.');
                    }
                } else {
                    setErrorMessage('We encountered an error while saving your details. Please try with diffrent username.');
                }
            })
            .catch((err) => console.error('Error saving user details:', err));
    };

    return (
        <div className="chat-page-container scoped-area">
            <header className="header">
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button className="logout-btn" onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>Sign in to join our supportive community.</p>
                        <button className="button" onClick={handleSignIn}>
                            Sign in with Google
                        </button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div className="registration-wrapper">
                        <div className="reg-form">
                            <h3>{volunteerFormExpanded ? "Volunteer Registration" : "Register"}</h3>
                            <div className="input-group">
                                <label htmlFor="fullname">
                                    {selectedRole === 'helpseeker' ? 'Username (anonymous)' : 'Username'}
                                </label>
                                <input
                                    id="fullname"
                                    type="text"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    placeholder="Enter your full name"
                                />
                            </div>

                            {selectedRole === 'volunteer' && (
                                <div className="input-group">
                                    <label htmlFor="phone">Phone Number (required)</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter your phone number"
                                    />
                                </div>
                            )}

                            <button className="submit-btn" onClick={handleSubmit}>
                                Join Now
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>

                        {!volunteerFormExpanded && (
                            <aside className="reg-sidebar">
                                <h2>Want to Help?</h2>
                                <p>
                                    If you are interested in supporting others, click the button below to register as a volunteer. Our team will contact and verify before onboarding.
                                </p>
                                <button className="volunteer-btn" onClick={handleVolunteerCTA}>
                                    I Want to Help!
                                </button>
                            </aside>
                        )}
                    </div>
                ) : (
                    <p>Loading registration...</p>
                )
            ) : (
                <div className="sign-in-container"></div>
            )}
        </div>
    );
};

export default ChatPage;
