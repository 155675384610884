// client/src/pages/VideoSession.js
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';

const VideoSession = () => {
    const location = useLocation();
    const [token, setToken] = useState('');
    const [roomId, setRoomId] = useState('');
    const [error, setError] = useState('');
    const [remoteStreams, setRemoteStreams] = useState({}); // Map: peerId => MediaStream
    const [localStream, setLocalStream] = useState(null);

    const localVideoRef = useRef(null);
    const socketRef = useRef(null);
    const peersRef = useRef({}); // Map: peerId => RTCPeerConnection

    // 1) Parse token from URL query
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const linkParam = searchParams.get('link');
        if (!linkParam) {
            setError('No link provided.');
        } else {
            setToken(linkParam);
        }
    }, [location.search]);

    // 2) Validate token with backend to retrieve roomId
    useEffect(() => {
        if (!token) return;
        fetch(`/api/video/join/${token}`)
            .then(res => res.json())
            .then(data => {
                if (!data.success) {
                    setError(data.message || 'Unable to join session');
                    return;
                }
                setRoomId(data.roomId);
            })
            .catch(async (err) => {
                const text = await err.text();
                setError(`Server error: ${text}`);
            });
    }, [token]);

    // 3) Setup local media and Socket.IO connection once roomId is available
    useEffect(() => {
        if (!roomId) return;

        // Connect to the signaling server
        socketRef.current = io();

        // Acquire local media stream (video and audio)
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                setLocalStream(stream);
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                // Join the room on the server
                socketRef.current.emit('joinRoom', roomId);
            })
            .catch(err => {
                console.error('getUserMedia error:', err);
                setError('Unable to access camera/microphone');
            });

        // Receive list of existing users in the room
        socketRef.current.on('allUsers', users => {
            // For each existing user, create a peer connection and send an offer
            users.forEach(userID => {
                createPeerConnection(userID, true);
            });
        });

        // When a new user joins after you have joined
        socketRef.current.on('newUserJoined', userID => {
            createPeerConnection(userID, false);
        });

        // Handle incoming offer from a peer
        socketRef.current.on('offer', async (payload) => {
            const { sdp, callerId } = payload;
            const peer = createPeerConnection(callerId, false);
            try {
                await peer.setRemoteDescription(new RTCSessionDescription(sdp));
                const answer = await peer.createAnswer();
                await peer.setLocalDescription(answer);
                socketRef.current.emit('answer', { sdp: answer, roomId, to: callerId });
            } catch (err) {
                console.error('Error handling offer:', err);
            }
        });

        // Handle incoming answer for an offer you sent
        socketRef.current.on('answer', async (payload) => {
            const { sdp, responderId } = payload;
            const peer = peersRef.current[responderId];
            if (!peer) return;
            try {
                await peer.setRemoteDescription(new RTCSessionDescription(sdp));
            } catch (err) {
                console.error('Error handling answer:', err);
            }
        });

        // Handle incoming ICE candidates from peers
        socketRef.current.on('ice-candidate', async (payload) => {
            const { candidate, from } = payload;
            const peer = peersRef.current[from];
            if (peer && candidate) {
                try {
                    await peer.addIceCandidate(new RTCIceCandidate(candidate));
                } catch (err) {
                    console.error('Error adding ICE candidate:', err);
                }
            }
        });

        // When a peer disconnects, clean up its connection and stream
        socketRef.current.on('userLeft', userID => {
            if (peersRef.current[userID]) {
                peersRef.current[userID].close();
                delete peersRef.current[userID];
            }
            setRemoteStreams(prev => {
                const updated = { ...prev };
                delete updated[userID];
                return updated;
            });
        });

        // Clean up on unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }
        };
    }, [roomId]);

    // Create or retrieve an RTCPeerConnection for a peer
    const createPeerConnection = (peerID, isInitiator) => {
        if (peersRef.current[peerID]) return peersRef.current[peerID];

        const peer = new RTCPeerConnection({
            iceServers: [
                { urls: 'stun:stun.l.google.com:19302' } // Replace with your own STUN/TURN if needed
            ]
        });

        // Add local media tracks to the connection
        if (localStream) {
            localStream.getTracks().forEach(track => {
                peer.addTrack(track, localStream);
            });
        }

        // When a remote track is received, update the remoteStreams state
        peer.ontrack = (event) => {
            setRemoteStreams(prev => ({ ...prev, [peerID]: event.streams[0] }));
        };

        // Relay ICE candidates to the peer via the signaling server
        peer.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current.emit('ice-candidate', {
                    candidate: event.candidate,
                    roomId,
                    to: peerID
                });
            }
        };

        peersRef.current[peerID] = peer;

        // If this client is the initiator, create an offer when negotiation is needed
        if (isInitiator) {
            peer.onnegotiationneeded = async () => {
                try {
                    const offer = await peer.createOffer();
                    await peer.setLocalDescription(offer);
                    socketRef.current.emit('offer', {
                        sdp: offer,
                        roomId,
                        to: peerID
                    });
                } catch (err) {
                    console.error('Error during negotiation:', err);
                }
            };
        }

        return peer;
    };

    return (
        <div style={{ padding: '1rem' }}>
            <h2>Video Session</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* Local Video */}
                <div>
                    <h4>Your Video</h4>
                    <video ref={localVideoRef} autoPlay muted playsInline style={{ width: '300px', margin: '0.5rem' }} />
                </div>
                {/* Remote Videos */}
                {Object.entries(remoteStreams).map(([peerID, stream]) => (
                    <div key={peerID}>
                        <h4>Peer: {peerID}</h4>
                        <video
                            autoPlay
                            playsInline
                            style={{ width: '300px', margin: '0.5rem' }}
                            ref={(video) => {
                                if (video) video.srcObject = stream;
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoSession;
