import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import "../../styles/assessment.css";

const DepressionAnxietyTest = () => {
    const [name, setName] = useState("");
    const [answers, setAnswers] = useState({});
    const [completed, setCompleted] = useState(false);
    const [nameSubmitted, setNameSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const resultRef = useRef(null);

    const questions = [
        { id: 1, text: "Over the past two weeks, have you felt little interest or pleasure in doing things?", category: "Depression" },
        { id: 2, text: "Have you felt down, depressed, or hopeless most days?", category: "Depression" },
        { id: 3, text: "Have you had trouble concentrating on everyday tasks?", category: "Depression" },
        { id: 4, text: "Do you feel tired no matter how much rest you get?", category: "Depression" },
        { id: 5, text: "Have you lost interest in activities you previously enjoyed?", category: "Depression" },
        { id: 6, text: "Have you felt that life isn’t worth living?", category: "Depression" },
        { id: 7, text: "Do you find yourself overeating or lacking appetite?", category: "Depression" },
        { id: 8, text: "Have you noticed yourself withdrawing from loved ones?", category: "Depression" },
        { id: 9, text: "How often do you feel nervous or anxious, even without reason?", category: "Anxiety" },
        { id: 10, text: "Do you feel like you cannot control your worrying?", category: "Anxiety" },
        { id: 11, text: "Have you experienced sudden feelings of fear or dread?", category: "Anxiety" },
        { id: 12, text: "How often do you feel your heart racing or trembling when stressed?", category: "Anxiety" },
        { id: 13, text: "Do you avoid specific situations because they make you anxious?", category: "Anxiety" },
        { id: 14, text: "Have you felt a constant sense of restlessness or unease?", category: "Anxiety" },
        { id: 15, text: "Do you worry excessively about small or everyday problems?", category: "Anxiety" },
        { id: 16, text: "Have you experienced shortness of breath due to anxiety?", category: "Anxiety" },
        { id: 17, text: "How often do you feel like you have too many responsibilities to manage?", category: "Stress" },
        { id: 18, text: "Do you feel like you lack control over your life?", category: "Stress" },
        { id: 19, text: "Have you been easily irritated or angry due to stress?", category: "Stress" },
        { id: 20, text: "How often do you feel physically exhausted due to stress?", category: "Stress" },
        { id: 21, text: "Have you found it difficult to relax, even during free time?", category: "Stress" },
        { id: 22, text: "How often do you have trouble falling asleep or staying asleep?", category: "Sleep and Fatigue" },
        { id: 23, text: "Do you wake up feeling unrefreshed, even after sleeping?", category: "Sleep and Fatigue" },
        { id: 24, text: "Have you noticed changes in your sleep patterns recently?", category: "Sleep and Fatigue" },
        { id: 25, text: "Do you rely on caffeine or other stimulants to get through the day?", category: "Sleep and Fatigue" },
        { id: 26, text: "How often do you feel like you lack energy throughout the day?", category: "Sleep and Fatigue" },
        { id: 27, text: "Do you feel disconnected from people who care about you?", category: "Social Connection" },
        { id: 28, text: "How often do you avoid social gatherings or events?", category: "Social Connection" },
        { id: 29, text: "Have you felt lonely, even when around others?", category: "Social Connection" },
        { id: 30, text: "Do you feel supported by family or friends during difficult times?", category: "Social Connection" },
        { id: 31, text: "Have you experienced difficulty making new friends?", category: "Social Connection" },
        { id: 32, text: "Do you find yourself repeatedly checking locks, stoves, or other items?", category: "OCD" },
        { id: 33, text: "Have you been bothered by intrusive thoughts you can’t control?", category: "OCD" },
        { id: 34, text: "Do you feel the need to perform rituals or routines excessively?", category: "OCD" },
        { id: 35, text: "Have compulsive behaviors interfered with your daily life?", category: "OCD" },
        { id: 36, text: "Do you have recurring, distressing memories of a past event?", category: "Trauma and PTSD" },
        { id: 37, text: "Have you avoided places or people that remind you of a traumatic event?", category: "Trauma and PTSD" },
        { id: 38, text: "How often do you feel jumpy or easily startled?", category: "Trauma and PTSD" },
        { id: 39, text: "Do you have trouble sleeping due to nightmares or flashbacks?", category: "Trauma and PTSD" },
        { id: 40, text: "Have you felt detached or numb since a traumatic experience?", category: "Trauma and PTSD" },
    ];
    const options = ["Never", "Rarely", "Sometimes", "Often", "Always"];

    const handleAnswerChange = (questionId, value) => {
        setAnswers((prev) => ({ ...prev, [questionId]: value }));
        setError(false);
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert("Please enter a valid name to proceed.");
            return;
        }
        setNameSubmitted(true);
    };

    const calculateResult = () => {
        const categoryScores = {};
        const categoryCounts = {};

        questions.forEach((q) => {
            const answer = parseInt(answers[q.id] || 0);
            categoryScores[q.category] = (categoryScores[q.category] || 0) + answer;
            categoryCounts[q.category] = (categoryCounts[q.category] || 0) + 1;
        });

        const normalizedScores = {};
        Object.keys(categoryScores).forEach((cat) => {
            const maxScore = categoryCounts[cat] * (options.length - 1);
            normalizedScores[cat] = ((categoryScores[cat] / maxScore) * 100).toFixed(1);
        });

        return normalizedScores;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(answers).length < questions.length) {
            setError(true);
            alert("Please answer all questions before submitting.");
            return;
        }
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            setCompleted(true);
        }, 1000);
    };

    const captureScreenshot = async () => {
        if (resultRef.current) {
            try {
                const canvas = await html2canvas(resultRef.current);
                const imageDataUrl = canvas.toDataURL("image/png");
                if (navigator.share) {
                    const blob = await (await fetch(imageDataUrl)).blob();
                    const file = new File([blob], "assessment-results.png", { type: "image/png" });
                    await navigator.share({
                        title: "My Assessment Results",
                        text: "Check out my mental health assessment results!",
                        files: [file],
                    });
                } else {
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    link.download = "assessment-results.png";
                    link.click();
                }
            } catch (error) {
                console.error("Error capturing screenshot:", error);
                alert("Failed to share or download results. Please try again.");
            }
        }
    };

    const completedQuestions = Object.keys(answers).length;
    const totalQuestions = questions.length;
    const progressPercentage = Math.round((completedQuestions / totalQuestions) * 100);

    const renderResults = () => {
        const results = calculateResult();
        const renderCategoryMessage = (score) => {
            if (score > 70) return "Consider seeking professional support.";
            if (score > 40) return "Monitor and manage your well-being.";
            return "You're doing great! Keep it up!";
        };

        return (
            <div className="result" ref={resultRef}>
                <h2>Assessment Results for {name}</h2>
                {Object.keys(results).map((category) => (
                    <div className="result-item" key={category}>
                        <div className="category">{category}</div>
                        <div className="result-progress-bar">
                            <div
                                className="result-progress-fill"
                                style={{
                                    width: `${results[category]}%`,
                                    backgroundColor:
                                        results[category] > 70
                                            ? "red"
                                            : results[category] > 40
                                                ? "yellow"
                                                : "green",
                                }}
                            ></div>
                        </div>
                        <p>{renderCategoryMessage(results[category])}</p>
                    </div>
                ))}
                <button className="button" onClick={() => navigate("/")}>Back to Home</button>
                <button className="button" onClick={captureScreenshot}>Share Results</button>
            </div>
        );
    };

    return (
        <div className="assessment-container">
            <h1 className="assessment-header">Depression & Anxiety Test</h1>
            {!nameSubmitted ? (
                <form onSubmit={handleNameSubmit} className="name-form">
                    <h2>What shall we call you?</h2>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">Start Assessment</button>
                </form>
            ) : !completed ? (
                <>
                    {error && <p className="error">Please answer all questions before submitting!</p>}
                    <div className="vertical-progress-bar">
                        <div className="progress-fill" style={{ height: `${progressPercentage}%` }}>
                            {progressPercentage}%
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="assessment-form">
                        {questions.map((q) => (
                            <div key={q.id} className="question">
                                <p>{q.text}</p>
                                <div className="options">
                                    {options.map((option, idx) => (
                                        <label key={idx} className="option-label" aria-label={`Option ${option} for question ${q.id}`}>
                                            <input
                                                type="radio"
                                                name={`question-${q.id}`}
                                                value={idx}
                                                onChange={() => handleAnswerChange(q.id, idx)}
                                                required
                                            />
                                            <span>{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <button className="button" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit Assessment"}
                        </button>
                    </form>
                </>
            ) : (
                renderResults()
            )}
        </div>
    );
};

export default DepressionAnxietyTest;
