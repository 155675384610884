// AboutUs.js

import React from 'react';
import '../styles/AboutUs.css';

const AboutUs = () => {
    return (
        <div className="aboutus-container">
            <section className="aboutus-hero">
                <h1 className="aboutus-title">About Us</h1>
                <p className="aboutus-subtitle">Empowering Your Mental Health Journey</p>
            </section>
            <section className="aboutus-content">
                <div className="aboutus-card">
                    <h2>Our Mission</h2>
                    <p>
                        To provide accessible mental health resources, support, and guidance, empowering individuals to lead happier and healthier lives.
                    </p>
                </div>
                <div className="aboutus-card">
                    <h2>Our Vision</h2>
                    <p>
                        To build a community where mental health is prioritized, stigma is eliminated, and every individual finds the support they need.
                    </p>
                </div>
                <div className="aboutus-card">
                    <h2>Our Values</h2>
                    <ul>
                        <li>Compassion</li>
                        <li>Inclusivity</li>
                        <li>Empowerment</li>
                        <li>Integrity</li>
                        <li>Innovation</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;
