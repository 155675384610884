import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";
import { Line } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import "../styles/journaling.css"; // Uses your updated CSS

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const moodOptions = [
    { value: "neutral", emoji: "😐", label: "Neutral" },
    { value: "happy", emoji: "😊", label: "Happy" },
    { value: "sad", emoji: "😢", label: "Sad" },
    { value: "stressed", emoji: "😰", label: "Stressed" },
    { value: "angry", emoji: "😡", label: "Angry" },
];

const JournalingPage = () => {
    const [entries, setEntries] = useState([]);
    const [currentEntry, setCurrentEntry] = useState("");
    const [mood, setMood] = useState("neutral");
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [view, setView] = useState("journal"); // "journal" or "tracker"

    // Check authentication and fetch entries on mount
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get("/auth/check", { withCredentials: true });
                if (response.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                    fetchEntries();
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error("Error checking authentication:", error);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    // Update body background based on current mood
    useEffect(() => {
        document.body.className = `modern-mood-${mood}`;
    }, [mood]);

    const fetchEntries = async () => {
        try {
            setLoading(true);
            const response = await axios.get("/journals", { withCredentials: true });
            setEntries(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching entries:", error);
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!currentEntry.trim()) {
            alert("Please write something before saving!");
            return;
        }
        try {
            const response = await axios.post("/journals", { content: currentEntry, mood });
            setEntries([response.data, ...entries]);
            setCurrentEntry("");
            setMood("neutral");
        } catch (error) {
            console.error("Error saving entry:", error);
        }
    };

    const handleSignIn = () => {
        window.location.href = "/auth/google";
    };

    const handleLogout = () => {
        window.location.href = "/auth/logout";
    };

    // Animation variants for list items
    const entryVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    // Prepare mood data aggregated by date for the chart
    const getMoodData = () => {
        const dataByDate = {};
        entries.forEach((entry) => {
            const date = new Date(entry.date).toLocaleDateString();
            if (!dataByDate[date]) dataByDate[date] = {};
            const entryMood = entry.mood;
            if (!dataByDate[date][entryMood]) dataByDate[date][entryMood] = 0;
            dataByDate[date][entryMood] += 1;
        });
        const sortedDates = Object.keys(dataByDate).sort((a, b) => new Date(a) - new Date(b));
        const moods = moodOptions.map((option) => option.value);
        const moodColors = {
            neutral: { border: "#9E9E9E", background: "rgba(158,158,158,0.2)" },
            happy: { border: "#4CAF50", background: "rgba(76,175,80,0.2)" },
            sad: { border: "#2196F3", background: "rgba(33,150,243,0.2)" },
            stressed: { border: "#FF9800", background: "rgba(255,152,0,0.2)" },
            angry: { border: "#F44336", background: "rgba(244,67,54,0.2)" },
        };
        const datasets = moods.map((moodKey) => ({
            label: moodKey.charAt(0).toUpperCase() + moodKey.slice(1),
            data: sortedDates.map((date) => dataByDate[date][moodKey] || 0),
            fill: true,
            borderColor: moodColors[moodKey].border,
            backgroundColor: moodColors[moodKey].background,
            tension: 0.1,
            pointRadius: 4,
        }));
        return {
            labels: sortedDates,
            datasets,
        };
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: "Mood Tracker Overview" },
        },
    };

    return (
        <div className="modern-journaling-page">
            <div className="modern-container">
                {/* Top Navigation Bar */}
                <header className="modern-navbar">
                    <div className="modern-brand">My Journal App</div>
                    <div className="modern-nav-buttons">
                        <button
                            className="modern-button"
                            onClick={() => setView("journal")}
                            style={{
                                background: view === "journal" ? "#7b42f6" : "#ddd",
                                color: view === "journal" ? "#fff" : "#000",
                            }}
                        >
                            Journal
                        </button>
                        <button
                            className="modern-button"
                            onClick={() => setView("tracker")}
                            style={{
                                background: view === "tracker" ? "#7b42f6" : "#ddd",
                                color: view === "tracker" ? "#fff" : "#000",
                            }}
                        >
                            Mood Tracker
                        </button>
                        {isAuthenticated && (
                            <button className="modern-button logout" onClick={handleLogout}>
                                Logout
                            </button>
                        )}
                    </div>
                </header>

                {/* Journal View */}
                {view === "journal" && (
                    <>
                        <h1 className="modern-page-title">Your Digital Journal</h1>
                        {!isAuthenticated ? (
                            <div className="modern-not-authenticated">
                                <p>Please sign in to start journaling and capture your moments.</p>
                                <button className="modern-button sign-in" onClick={handleSignIn}>
                                    Sign in with Google
                                </button>
                            </div>
                        ) : (
                            <motion.div
                                className="modern-journal-content"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <div className="modern-entry-section">
                                    <div className="modern-emoji-picker">
                                        {moodOptions.map((option) => (
                                            <motion.button
                                                key={option.value}
                                                className={`modern-emoji-button ${mood === option.value ? "selected" : ""}`}
                                                onClick={() => setMood(option.value)}
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                title={option.label}
                                            >
                                                <span role="img" aria-label={option.label}>
                                                    {option.emoji}
                                                </span>
                                            </motion.button>
                                        ))}
                                    </div>
                                    <TextareaAutosize
                                        className="modern-entry-textarea lined-paper"
                                        value={currentEntry}
                                        onChange={(e) => setCurrentEntry(e.target.value)}
                                        placeholder="How was your day? Write your thoughts here..."
                                        minRows={10}
                                        maxRows={30}
                                    />
                                    <button className="modern-button save-button" onClick={handleSave}>
                                        Save Entry
                                    </button>
                                </div>
                                {loading && <p className="modern-loading-message">Loading your memories...</p>}
                                <h2 className="modern-past-entries-title">Previous Entries</h2>
                                <AnimatePresence>
                                    <ul className="modern-entries-list">
                                        {entries.map((entry) => (
                                            <motion.li
                                                className="modern-entry-item"
                                                key={entry._id}
                                                variants={entryVariants}
                                                initial="hidden"
                                                animate="visible"
                                                exit="hidden"
                                                transition={{ duration: 0.3 }}
                                            >
                                                <div className="modern-entry-card">
                                                    <p className="modern-entry-text">{entry.content}</p>
                                                    <small className="modern-entry-meta">
                                                        {new Date(entry.date).toLocaleString()} - Mood: {entry.mood}
                                                    </small>
                                                </div>
                                            </motion.li>
                                        ))}
                                    </ul>
                                </AnimatePresence>
                            </motion.div>
                        )}
                    </>
                )}

                {/* Mood Tracker View */}
                {view === "tracker" && (
                    <div>
                        <h1 className="modern-page-title">Mood Tracker</h1>
                        <div style={{ marginBottom: "20px", textAlign: "center" }}>
                            <button className="modern-button" onClick={() => setView("journal")}>
                                Back to Journal
                            </button>
                        </div>
                        {entries.length > 0 ? (
                            <div style={{ position: "relative", height: "50vh", width: "100%" }}>
                                <Line data={getMoodData()} options={chartOptions} />
                            </div>
                        ) : (
                            <div style={{ textAlign: "center", padding: "40px" }}>
                                <p className="modern-loading-message">
                                    No mood data available yet. Start journaling to see your mood trends!
                                </p>
                                <button className="modern-button" onClick={() => setView("journal")}>
                                    Start Journaling
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default JournalingPage;
