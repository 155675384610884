// src/pages/AssessmentLanding.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/main.css';
import '../styles/home.css'; // or your custom CSS

const fadeInUp = {
    hidden: { opacity: 0, y: 25 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, ease: 'easeOut' },
    },
};

const AssessmentLanding = () => {
    const navigate = useNavigate();

    return (
        <motion.div
            className="assessment-landing-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{ padding: '2rem' }}
        >
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Choose an Assessment
            </motion.h1>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                Please select the type of assessment you’d like to take. We’ve designed it to be long so your results will be as accurate as possible.
            </motion.p>

            <motion.div
                className="features"
                initial="hidden"
                animate="visible"
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.3,
                        },
                    },
                }}
            >
                <motion.div
                    className="feature"
                    onClick={() => navigate('/assessment/depression-anxiety')}
                    variants={fadeInUp}
                >
                    <div className="feature-icon">😟</div>
                    <h2 className="feature-title">Depression/Anxiety Test</h2>
                    <p className="feature-description">
                        Gauge your levels of depression or anxiety with clinically informed questionnaires.
                    </p>
                </motion.div>

                <motion.div
                    className="feature"
                    onClick={() => navigate('/assessment/personality')}
                    variants={fadeInUp}
                >
                    <div className="feature-icon">🧠</div>
                    <h2 className="feature-title">Personality Test</h2>
                    <p className="feature-description">
                        Learn more about your personality traits and tendencies.
                    </p>
                </motion.div>

                <motion.div
                    className="feature"
                    onClick={() => navigate('/assessment/disorder')}
                    variants={fadeInUp}
                >
                    <div className="feature-icon">🔎</div>
                    <h2 className="feature-title">Disorder Screening</h2>
                    <p className="feature-description">
                        Explore potential signs of mood or behavioral disorders.
                    </p>
                </motion.div>
            </motion.div>
        </motion.div>
    );
};

export default AssessmentLanding;
