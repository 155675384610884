import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import "../../styles/assessment.css";

const PersonalityTest = () => {
    const [name, setName] = useState("");
    const [answers, setAnswers] = useState({});
    const [completed, setCompleted] = useState(false);
    const [nameSubmitted, setNameSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const resultRef = useRef(null);

    // ---------------------------------
    //   QUESTIONS & DICHOTOMIES
    // ---------------------------------
    // "IE" => Introversion (I) vs. Extraversion (E)
    // "SN" => Sensing (S) vs. iNtuition (N)
    // "TF" => Thinking (T) vs. Feeling (F)
    // "JP" => Judging (J) vs. Perceiving (P)
    //
    // Each answer is 0..4 (Never..Always).
    // We’ll ALWAYS pick whichever side is higher, or default to the first letter if tied.

    const questions = [
        // I/E
        {
            id: 1,
            text: "Do you feel more energetic when spending time alone?",
            dimension: "IE",
            direction: "I",
        },
        {
            id: 2,
            text: "Do you get excited in big social gatherings?",
            dimension: "IE",
            direction: "E",
        },
        {
            id: 3,
            text: "Do you prefer one-on-one conversations over group discussions?",
            dimension: "IE",
            direction: "I",
        },
        {
            id: 4,
            text: "Do you quickly join new social events, even if you know nobody?",
            dimension: "IE",
            direction: "E",
        },
        {
            id: 17,
            text: "Do you usually keep thoughts to yourself unless asked?",
            dimension: "IE",
            direction: "I",
        },
        {
            id: 18,
            text: "Does being around people most of the day rarely drain your energy?",
            dimension: "IE",
            direction: "E",
        },

        // S/N
        {
            id: 5,
            text: "Do you mainly trust concrete facts and real examples?",
            dimension: "SN",
            direction: "S",
        },
        {
            id: 6,
            text: "Do you often daydream about future possibilities and new ideas?",
            dimension: "SN",
            direction: "N",
        },
        {
            id: 7,
            text: "Do you focus on small details and prefer step-by-step instructions?",
            dimension: "SN",
            direction: "S",
        },
        {
            id: 8,
            text: "Do you enjoy brainstorming and imagining different outcomes?",
            dimension: "SN",
            direction: "N",
        },
        {
            id: 19,
            text: "Do you trust your five senses more than hunches or instincts?",
            dimension: "SN",
            direction: "S",
        },
        {
            id: 20,
            text: "Do abstract or theoretical ideas excite you more than practical tasks?",
            dimension: "SN",
            direction: "N",
        },

        // T/F
        {
            id: 9,
            text: "When making decisions, do you rely mostly on logic over personal feelings?",
            dimension: "TF",
            direction: "T",
        },
        {
            id: 10,
            text: "Do you consider others’ emotions before making a judgment?",
            dimension: "TF",
            direction: "F",
        },
        {
            id: 11,
            text: "Do you believe fairness means applying the same rules to everyone?",
            dimension: "TF",
            direction: "T",
        },
        {
            id: 12,
            text: "When deciding, do you think about how your choice affects people’s feelings?",
            dimension: "TF",
            direction: "F",
        },
        {
            id: 21,
            text: "Would you rather point out flawed logic than spare someone's feelings?",
            dimension: "TF",
            direction: "T",
        },
        {
            id: 22,
            text: "Do you naturally empathize with others’ problems and want to comfort them?",
            dimension: "TF",
            direction: "F",
        },

        // J/P
        {
            id: 13,
            text: "Do you like having a clear plan or schedule, and get stressed by last-minute changes?",
            dimension: "JP",
            direction: "J",
        },
        {
            id: 14,
            text: "Are you flexible and spontaneous, preferring to keep things open-ended?",
            dimension: "JP",
            direction: "P",
        },
        {
            id: 15,
            text: "Do you create to-do lists and prefer tasks in a fixed order?",
            dimension: "JP",
            direction: "J",
        },
        {
            id: 16,
            text: "Do you dislike rigid schedules and prefer going with the flow?",
            dimension: "JP",
            direction: "P",
        },
        {
            id: 23,
            text: "Do you like to finalize decisions quickly rather than explore multiple options?",
            dimension: "JP",
            direction: "J",
        },
        {
            id: 24,
            text: "Do you frequently change plans if something more interesting comes up?",
            dimension: "JP",
            direction: "P",
        },
    ];

    // Radio options
    const options = ["Never", "Rarely", "Sometimes", "Often", "Always"];

    // ---------------------------------
    //   EVENT HANDLERS
    // ---------------------------------
    const handleAnswerChange = (questionId, value) => {
        setAnswers((prev) => ({ ...prev, [questionId]: value }));
        setError(false);
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert("Please enter a valid name to proceed.");
            return;
        }
        setNameSubmitted(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(answers).length < questions.length) {
            setError(true);
            alert("Please answer all questions before submitting.");
            return;
        }
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            setCompleted(true);
        }, 800);
    };

    // ---------------------------------
    //   SCORING: ALWAYS PICK ONE LETTER
    // ---------------------------------
    // If tie => default to first letter
    const calculateDimensionLetter = (scores) => {
        const [dimA, dimB] = Object.keys(scores); // e.g. ["I", "E"]
        // If tie or dimA is greater, pick dimA, else dimB
        return scores[dimA] >= scores[dimB] ? dimA : dimB;
    };

    const calculateResult = () => {
        const dimensionScores = {
            IE: { I: 0, E: 0 },
            SN: { S: 0, N: 0 },
            TF: { T: 0, F: 0 },
            JP: { J: 0, P: 0 },
        };

        // Sum up answers for each dimension
        questions.forEach((q) => {
            const answerValue = parseInt(answers[q.id] || 0);
            dimensionScores[q.dimension][q.direction] += answerValue;
        });

        // Determine each letter
        const iOrE = calculateDimensionLetter(dimensionScores.IE);
        const sOrN = calculateDimensionLetter(dimensionScores.SN);
        const tOrF = calculateDimensionLetter(dimensionScores.TF);
        const jOrP = calculateDimensionLetter(dimensionScores.JP);

        return `${iOrE}${sOrN}${tOrF}${jOrP}`;
    };

    // ---------------------------------
    //   MBTI DESCRIPTIONS
    // ---------------------------------
    const typeDescriptions = {
        ISTJ: {
            compliment: "Inspector",
            description: "🔎 ISTJs are reliable and practical, grounded in logic and tradition.",
            strengths: ["Organized", "Dependable", "Detail-oriented", "Realistic"],
            weaknesses: ["Resistant to change", "Overly critical", "Struggles with abstract ideas"],
            career: "ISTJs excel in accounting, project management, or any structured, detail-oriented field.",
        },
        ISFJ: {
            compliment: "Protector",
            description: "🛡️ ISFJs are caring and loyal, often putting others’ needs first.",
            strengths: ["Empathetic", "Reliable", "Supportive", "Meticulous"],
            weaknesses: ["May ignore personal needs", "Sensitive to criticism", "Risk-averse"],
            career: "ISFJs thrive in supportive roles like nursing, social work, or administration.",
        },
        INFJ: {
            compliment: "Counselor",
            description: "🔮 INFJs are idealistic and intuitive, seeking deep meaning and connection.",
            strengths: ["Insightful", "Compassionate", "Creative", "Principled"],
            weaknesses: ["Overly idealistic", "Private or reserved", "Emotionally sensitive"],
            career: "INFJs flourish in counseling, writing, or advocacy roles blending vision and empathy.",
        },
        INTJ: {
            compliment: "Mastermind",
            description: "🧠 INTJs are analytical and strategic, driven by knowledge and competence.",
            strengths: ["Independent", "Strategic", "Focused", "Logical"],
            weaknesses: ["Can be aloof", "High standards", "Difficulty expressing emotion"],
            career: "INTJs excel in engineering, scientific research, or complex problem-solving fields.",
        },
        ISTP: {
            compliment: "Virtuoso",
            description: "🛠️ ISTPs are hands-on problem-solvers, calm under pressure and adaptable.",
            strengths: ["Calm", "Observant", "Logical", "Adaptable"],
            weaknesses: ["Impulsive", "May struggle with long-term planning", "Detached at times"],
            career: "ISTPs do well in mechanics, engineering, or any active, troubleshooting-based job.",
        },
        ISFP: {
            compliment: "Composer",
            description: "🎨 ISFPs are gentle creators with a strong sense of personal expression.",
            strengths: ["Creative", "Sensitive", "Loyal", "Adaptable"],
            weaknesses: ["Conflict-averse", "Sensitive to criticism", "Indecisive under pressure"],
            career: "ISFPs excel in creative and service fields like design, childcare, or healthcare.",
        },
        INFP: {
            compliment: "Dreamer",
            description: "🌈 INFPs are thoughtful idealists, guided by strong personal values.",
            strengths: ["Empathetic", "Idealistic", "Open-minded", "Introspective"],
            weaknesses: ["Overly idealistic", "Conflict-avoidant", "Indecisive"],
            career: "INFPs thrive in creative or humanitarian work like writing, counseling, or nonprofits.",
        },
        INTP: {
            compliment: "Architect",
            description: "🔬 INTPs are curious thinkers who love exploring complex concepts logically.",
            strengths: ["Analytical", "Independent", "Objective", "Innovative"],
            weaknesses: ["Overthinkers", "Dislike routine tasks", "Can seem distant"],
            career: "INTPs flourish in software development, research, or any role requiring deep analysis.",
        },
        ESTP: {
            compliment: "Dynamo",
            description: "🚀 ESTPs are energetic risk-takers who adapt quickly and love excitement.",
            strengths: ["Confident", "Resourceful", "Quick-thinking", "Observant"],
            weaknesses: ["Impatient with details", "Risk-prone", "Can be tactless"],
            career: "ESTPs excel in sales, marketing, entrepreneurship, or emergency response roles.",
        },
        ESFP: {
            compliment: "Entertainer",
            description: "🎉 ESFPs are outgoing performers, always up for social fun and new experiences.",
            strengths: ["Enthusiastic", "Sociable", "Spontaneous", "Fun-loving"],
            weaknesses: ["Easily bored by routine", "Impulsive", "May avoid tough tasks"],
            career: "ESFPs thrive in hospitality, entertainment, event planning, or sales.",
        },
        ENFP: {
            compliment: "Champion",
            description: "🌟 ENFPs are imaginative and empathetic, always ready to inspire others.",
            strengths: ["Creative", "Open-minded", "Enthusiastic", "Empathetic"],
            weaknesses: ["Disorganized", "Can be overly idealistic", "Bored by routine"],
            career: "ENFPs do well in public relations, teaching, writing, or consulting fields.",
        },
        ENTP: {
            compliment: "Visionary",
            description: "💬 ENTPs are innovative and enjoy debate, pushing boundaries and ideas.",
            strengths: ["Analytical", "Charismatic", "Flexible", "Inventive"],
            weaknesses: ["Argumentative", "Easily bored by details", "Poor follow-through"],
            career: "ENTPs thrive in entrepreneurship, law, tech startups—where debate and creativity matter.",
        },
        ESTJ: {
            compliment: "Executive",
            description: "🗂️ ESTJs are efficient organizers who value structure and results.",
            strengths: ["Organized", "Logical", "Decisive", "Dependable"],
            weaknesses: ["Can be rigid", "Overly critical", "May overlook emotional nuance"],
            career: "ESTJs flourish in management, finance, or any structured leadership role.",
        },
        ESFJ: {
            compliment: "Caregiver",
            description: "👥 ESFJs are warm and supportive, focusing on harmony and helping others.",
            strengths: ["Team-oriented", "Attentive to details", "Reliable", "Caring"],
            weaknesses: ["Sensitive to criticism", "Prioritize others too much", "Dislikes change"],
            career: "ESFJs do well in nursing, teaching, event coordination, or caring, people-focused work.",
        },
        ENFJ: {
            compliment: "Teacher",
            description: "🪄 ENFJs are inspiring leaders who enjoy guiding and uplifting others.",
            strengths: ["Empathetic", "Persuasive", "Visionary", "Organized"],
            weaknesses: ["Overcommitted sometimes", "Overly idealistic", "Struggles with boundaries"],
            career: "ENFJs thrive in education, counseling, HR, or any role combining leadership with empathy.",
        },
        ENTJ: {
            compliment: "Commander",
            description: "🏆 ENTJs are strong-willed strategists with big goals and high standards.",
            strengths: ["Strategic", "Decisive", "Confident", "Goal-driven"],
            weaknesses: ["Overly critical", "Impatient with slow progress", "Can steamroll others' ideas"],
            career: "ENTJs excel in leadership, entrepreneurship, consulting—wherever vision and drive shine.",
        },
    };

    // ---------------------------------
    //   RENDER RESULTS
    // ---------------------------------
    const captureScreenshot = async () => {
        if (resultRef.current) {
            try {
                const canvas = await html2canvas(resultRef.current);
                const imageDataUrl = canvas.toDataURL("image/png");
                if (navigator.share) {
                    // Web Share API
                    const blob = await (await fetch(imageDataUrl)).blob();
                    const file = new File([blob], "personality-results.png", {
                        type: "image/png",
                    });
                    await navigator.share({
                        title: "My Personality Test Results",
                        text: "Check out my MBTI results!",
                        files: [file],
                    });
                } else {
                    // Fallback: direct download
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    link.download = "personality-results.png";
                    link.click();
                }
            } catch (error) {
                console.error("Error capturing screenshot:", error);
                alert("Failed to share or download results. Please try again.");
            }
        }
    };

    const renderResults = () => {
        const finalType = calculateResult();
        const standardDescription = typeDescriptions[finalType];

        // Because there's no "X" now, we should always match one of the 16 standard types
        if (!standardDescription) {
            return (
                <div className="result" ref={resultRef}>
                    <h2>Personality Results for {name}</h2>
                    <div className="result-item">
                        <h3 className="category">
                            {finalType} - Couldn’t find a matching description
                        </h3>
                        <p>
                            We couldn’t find a description for this code. Please verify
                            the logic or MBTI structure.
                        </p>
                    </div>
                    <button className="button" onClick={() => navigate("/")}>
                        Back to Home
                    </button>
                </div>
            );
        }

        const { compliment, description, strengths, weaknesses, career } =
            standardDescription;

        return (
            <div className="result" ref={resultRef}>
                <h2>Personality Results for {name}</h2>
                <div className="result-item">
                    <h3 className="category">
                        {finalType} - {compliment}
                    </h3>
                    <p>{description}</p>
                    <p>
                        <strong>Strengths:</strong> {strengths.join(", ")}
                    </p>
                    <p>
                        <strong>Weaknesses:</strong> {weaknesses.join(", ")}
                    </p>
                    <p>
                        <strong>Career:</strong> {career}
                    </p>
                </div>
                <button className="button" onClick={() => navigate("/")}>
                    Back to Home
                </button>
                <button className="button" onClick={captureScreenshot}>
                    Share Results
                </button>
            </div>
        );
    };

    // ---------------------------------
    //   PROGRESS BAR
    // ---------------------------------
    const completedQuestions = Object.keys(answers).length;
    const totalQuestions = questions.length;
    const progressPercentage = Math.round(
        (completedQuestions / totalQuestions) * 100
    );

    // ---------------------------------
    //   MAIN RENDER
    // ---------------------------------
    return (
        <div className="assessment-container">
            {!nameSubmitted ? (
                <form onSubmit={handleNameSubmit} className="name-form">
                    <h2>Discover Your Personality Type!</h2>
                    <p style={{ marginBottom: "1rem" }}>
                        Please enter your name to begin:
                    </p>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">
                        Start Test
                    </button>
                </form>
            ) : !completed ? (
                <>
                    {error && (
                        <p className="error">
                            Please answer all questions before submitting!
                        </p>
                    )}
                    <div className="vertical-progress-bar">
                        <div
                            className="progress-fill"
                            style={{ height: `${progressPercentage}%` }}
                        >
                            {progressPercentage}%
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="assessment-form">
                        {questions.map((q) => (
                            <div key={q.id} className="question">
                                <p>{q.text}</p>
                                <div className="options">
                                    {options.map((option, idx) => (
                                        <label
                                            key={idx}
                                            className="option-label"
                                            aria-label={`Option ${option} for question ${q.id}`}
                                        >
                                            <input
                                                type="radio"
                                                name={`question-${q.id}`}
                                                value={idx}
                                                onChange={() => handleAnswerChange(q.id, idx)}
                                                required
                                            />
                                            <span>{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <button className="button" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit Test"}
                        </button>
                    </form>
                </>
            ) : (
                renderResults()
            )}
        </div>
    );
};

export default PersonalityTest;
