import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import '../styles/chat.css';

const socket = io.connect('https://danezah.com'); // Use your production server URL

const Chat = () => {
    const { username } = useParams(); // Chat partner's username from the URL
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null);
    const chatBoxRef = useRef(null);
    const textareaRef = useRef(null);

    // Request notification permission on mount
    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().catch(console.error);
        }
    }, []);

    // Fetch logged-in user details and register with the socket server
    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setUser(data.user);
                    socket.emit('register', data.user.username);
                } else {
                    console.error('User not authenticated');
                }
            })
            .catch((error) => console.error('Error fetching user details:', error));
    }, []);

    // Fetch chat history and listen for incoming messages
    useEffect(() => {
        if (user) {
            fetch(`/messages/${username}`, { credentials: 'include' })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setMessages(data.messages);
                    } else {
                        console.error('Failed to load messages');
                    }
                })
                .catch((error) => console.error('Error fetching messages:', error));

            socket.on('receive_message', (message) => {
                // Prevent duplicates by checking if the latest message is the same as incoming one
                setMessages((prev) => {
                    const lastMsg = prev[prev.length - 1];
                    if (lastMsg && lastMsg.timestamp === message.timestamp) {
                        return prev;
                    }
                    return [...prev, message];
                });
                // Show browser notification if the message is from the chat partner
                if (
                    message.sender !== user.username &&
                    Notification.permission === 'granted'
                ) {
                    new Notification(`New message from ${message.sender}`, {
                        body: message.message,
                        icon: '/path-to-icon.png',
                    });
                }
            });

            return () => socket.off('receive_message');
        }
    }, [username, user]);

    // Automatically scroll to the latest message
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTo({
                top: chatBoxRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);

    // Send a new message without the immediate duplicate addition
    const sendMessage = () => {
        if (newMessage.trim() && user) {
            const message = {
                sender: user.username,
                receiver: username,
                message: newMessage,
            };

            socket.emit('send_message', message);
            // Do not add the message immediately;
            // let the 'receive_message' event update the message list.
            setNewMessage('');
            adjustTextareaHeight();
        }
    };

    // Adjust textarea height dynamically
    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    // Handle key presses in the textarea
    const handleKeyDown = (e) => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            if (e.key === 'Enter') {
                adjustTextareaHeight();
            }
        } else {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
            } else if (e.key === 'Enter' && e.shiftKey) {
                adjustTextareaHeight();
            }
        }
    };

    return (
        <div className="chat-container">
            <header className="chat-header">
                <button className="top-button" onClick={() => navigate(-1)}>
                    Back
                </button>
                <h1>Connect with {username}</h1>
            </header>
            <main className="chat-box" ref={chatBoxRef}>
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message ${msg.sender === user?.username ? 'sent' : 'received'}`}
                        data-timestamp={new Date(msg.timestamp).toLocaleTimeString()}
                    >
                        <p>{msg.message}</p>
                    </div>
                ))}
            </main>
            <footer className="input-container">
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                        adjustTextareaHeight();
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Share your thoughts..."
                    rows="1"
                ></textarea>
                <button className="send-button" onClick={sendMessage}>
                    ✈️
                </button>
            </footer>
        </div>
    );
};

export default Chat;
