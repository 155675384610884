import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import '../styles/wellnessseekerdashboard.css';
import { FaStar } from 'react-icons/fa';

function unifyId(obj) {
    if (obj && obj._id && typeof obj._id === 'object' && obj._id.$oid) {
        obj._id = obj._id.$oid;
    }
}

function unifyAvailability(availability = []) {
    availability.forEach((slot) => {
        if (slot._id && typeof slot._id === 'object' && slot._id.$oid) {
            slot._id = slot._id.$oid;
        }
        if (slot.bookedBy) unifyId(slot.bookedBy);
    });
}

function unifyTherapist(t) {
    unifyId(t);
    if (Array.isArray(t.availability)) {
        unifyAvailability(t.availability);
    }
}

function unifyTherapistArray(therapists) {
    therapists.forEach((th) => unifyTherapist(th));
}

export default function WellnessSeekerDashboard() {
    // Data states
    const [therapists, setTherapists] = useState([]);
    const [filteredTherapists, setFilteredTherapists] = useState([]);
    const [myBookings, setMyBookings] = useState([]);
    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    // Filters
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [filterMethod, setFilterMethod] = useState('');
    const [filterLanguage, setFilterLanguage] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);

    // Selection & Rating
    const [selectedTherapist, setSelectedTherapist] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);

    // Navigation state – "therapists", "bookings", "profile"
    const [activeTab, setActiveTab] = useState("therapists");

    // For profile editing (wellness seeker profile)
    const [description, setDescription] = useState('');
    const [editingProfile, setEditingProfile] = useState(false);

    // Check user & set profile info
    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((r) => r.json())
            .then((data) => {
                if (data.isAuthenticated && data.user.therapyrole === 'wellnessseeker') {
                    unifyId(data.user);
                    setUser(data.user);
                    setDescription(data.user.description || '');
                    fetchMyBookings(data.user._id);
                } else {
                    setErrorMessage('You must be a wellness seeker to access this page.');
                }
            })
            .catch(() => setErrorMessage('Failed to authenticate user.'));
    }, []);

    // Load approved therapists
    useEffect(() => {
        fetch('/therapists/approved', { credentials: 'include' })
            .then((r) => {
                if (!r.ok) throw new Error(`Failed to load therapists: ${r.status}`);
                return r.json();
            })
            .then((data) => {
                unifyTherapistArray(data);
                setTherapists(data);
                setFilteredTherapists(data);
            })
            .catch((e) => setErrorMessage(e.message));
    }, []);

    // Filter logic with date-based filtering
    useEffect(() => {
        let temp = [...therapists];

        // Name filter
        if (searchQuery.trim()) {
            const q = searchQuery.toLowerCase();
            temp = temp.filter((t) => t.displayName.toLowerCase().includes(q));
        }

        // Date filter (assumes availability.day is in "YYYY-MM-DD" format)
        if (selectedDate) {
            temp = temp.filter((t) => {
                if (!t.availability || t.availability.length === 0) return false;
                return t.availability.some((slot) => slot.day === selectedDate);
            });
        }

        // Communication method filter
        if (filterMethod) {
            temp = temp.filter((t) => t.communicationMethods?.includes(filterMethod));
        }

        // Language filter
        if (filterLanguage.trim()) {
            const lang = filterLanguage.trim().toLowerCase();
            temp = temp.filter((t) => t.languages?.some((l) => l.toLowerCase() === lang));
        }

        setFilteredTherapists(temp);
    }, [searchQuery, selectedDate, filterMethod, filterLanguage, therapists]);

    // Fetch wellness seeker bookings
    function fetchMyBookings(seekerId) {
        fetch(`/api/book/wellnessseeker/${seekerId}`, { credentials: 'include' })
            .then((r) => r.json())
            .then((data) => {
                if (!data.success) throw new Error(data.message);
                data.bookings.forEach((bk) => {
                    if (bk.therapistId) unifyId(bk.therapistId);
                });
                setMyBookings(data.bookings);
            })
            .catch((e) => console.error('fetchMyBookings error:', e));
    }

    // Book therapist slot and trigger email sending via SMTP
    async function bookTherapist() {
        if (!selectedTherapist || !selectedSlot) return;
        try {
            const res = await fetch('/api/book', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    therapistId: selectedTherapist._id,
                    day: selectedSlot.day,
                    startTime: selectedSlot.startTime,
                    endTime: selectedSlot.endTime,
                }),
            });
            const data = await res.json();
            if (!data.success) throw new Error(data.message || 'Booking failed');

            // Alert user and trigger email send (assumes backend endpoint exists)
            alert('Session booked. Meeting link will be mail to you.');
            await fetch('/api/send-meeting-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    bookingId: data.bookingId,  // ensure your backend returns a bookingId
                    userEmail: user.email,
                    meetingToken: data.meetingToken  // include meeting token if available
                }),
            });

            // Refresh therapists list
            const updatedRes = await fetch('/therapists/approved', { credentials: 'include' });
            const updatedTherapists = await updatedRes.json();
            unifyTherapistArray(updatedTherapists);
            setTherapists(updatedTherapists);
            setFilteredTherapists(updatedTherapists);

            // Refresh bookings
            if (user) {
                fetchMyBookings(user._id);
            }
            setSelectedSlot(null);
            setSelectedTherapist(null);
        } catch (err) {
            alert(`Booking error: ${err.message}`);
        }
    }

    // Submit rating for therapist
    async function submitRating() {
        if (!selectedTherapist || !rating) {
            alert('Please pick a rating first');
            return;
        }
        try {
            const res = await fetch('/api/therapist/rate', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ therapistId: selectedTherapist._id, rating }),
            });
            const data = await res.json();
            if (!data.success) throw new Error(data.message);
            alert(`You rated ${selectedTherapist.displayName} ${rating} star(s)! ⭐`);

            // Refresh therapists
            const updated = await fetch('/therapists/approved', { credentials: 'include' })
                .then((r) => r.json());
            unifyTherapistArray(updated);
            setTherapists(updated);
            setFilteredTherapists(updated);
        } catch (err) {
            alert(`Rating error: ${err.message}`);
        }
    }

    // (Video session functionality removed due to technical limitations)
    // function startVideoSession(token) {
    //     window.location.href = `/video-session?link=${encodeURIComponent(token)}`;
    // }

    // Cancel a booking
    async function cancelBooking(sessionId) {
        try {
            const res = await fetch(`/api/book/${sessionId}/cancel`, {
                method: 'PUT',
                credentials: 'include',
            });
            const data = await res.json();
            if (!data.success) throw new Error(data.message);
            alert('Booking canceled ❌');
            if (user) {
                fetchMyBookings(user._id);
            }
            // Refresh therapists list
            const updated = await fetch('/therapists/approved', { credentials: 'include' })
                .then((r) => r.json());
            unifyTherapistArray(updated);
            setTherapists(updated);
            setFilteredTherapists(updated);
        } catch (err) {
            alert(`Cancel error: ${err.message}`);
        }
    }

    // Modal close
    function closeModal() {
        setSelectedTherapist(null);
        setSelectedSlot(null);
        setRating(0);
        setHoverRating(0);
    }

    // When a therapist card is clicked
    function handleTherapistClick(t) {
        setSelectedTherapist(t);
        setSelectedSlot(null);
        setRating(0);
        setHoverRating(0);
    }

    // Determine if slot is booked by the current user
    function isSlotMine(slot) {
        if (!user || !slot.bookedBy) return false;
        return slot.bookedBy._id === user._id;
    }

    // Toggle filter panel open/close
    function toggleFilters() {
        setFiltersOpen(!filtersOpen);
    }

    // Handle profile update (for wellness seeker)
    async function handleProfileUpdate() {
        try {
            const res = await fetch(`/api/wellnessseeker/${user._id}/profile`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ description }),
            });
            const data = await res.json();
            if (!res.ok) throw new Error(data.message || 'Profile update error');
            alert('Profile updated ✅');
            setEditingProfile(false);
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    return (
        <div className="ws-dashboard-container">
            {/* Header Panel with Navigation Buttons */}
            <header className="ws-header">
                <div className="ws-header-left">
                    <h1>Find &amp; Book a Therapist</h1>
                </div>
                <nav className="ws-header-nav">
                    <button
                        className={`ws-nav-btn ${activeTab === "therapists" ? "active" : ""}`}
                        onClick={() => setActiveTab("therapists")}
                    >
                        Search Therapists
                    </button>
                    <button
                        className={`ws-nav-btn ${activeTab === "bookings" ? "active" : ""}`}
                        onClick={() => setActiveTab("bookings")}
                    >
                        My Bookings
                    </button>
                    <button
                        className={`ws-nav-btn ${activeTab === "profile" ? "active" : ""}`}
                        onClick={() => setActiveTab("profile")}
                    >
                        My Profile
                    </button>
                    <button
                        className="ws-nav-btn ws-logout-button"
                        onClick={() => (window.location.href = '/auth/logout')}
                    >
                        Logout
                    </button>
                </nav>
            </header>

            {errorMessage && <p className="ws-error-message">{errorMessage}</p>}

            {/* Main Content */}
            {activeTab === "therapists" && (
                <div className="ws-layout">
                    {filtersOpen ? (
                        <aside className="ws-sidebar-left">
                            <div className="filters-toggle">
                                <button className="btn" onClick={toggleFilters}>
                                    Hide Filters 🙈
                                </button>
                            </div>
                            <section className="ws-filters collapsible-panel">
                                <h3>Filters</h3>
                                <div className="ws-filter-group">
                                    <label htmlFor="search">Search by Name:</label>
                                    <input
                                        id="search"
                                        type="text"
                                        placeholder="Therapist name..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="ws-filter-group">
                                    <label htmlFor="dateFilter">Select Date:</label>
                                    <input
                                        id="dateFilter"
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                    />
                                </div>
                                <div className="ws-filter-group">
                                    <label htmlFor="methodFilter">Communication:</label>
                                    <select
                                        id="methodFilter"
                                        value={filterMethod}
                                        onChange={(e) => setFilterMethod(e.target.value)}
                                    >
                                        <option value="">Any</option>
                                        <option value="Inperson">Inperson</option>
                                        <option value="Virtual">Virtual</option>
                                    </select>
                                </div>
                                <div className="ws-filter-group">
                                    <label htmlFor="languageFilter">Language:</label>
                                    <input
                                        id="languageFilter"
                                        type="text"
                                        placeholder="e.g. English"
                                        value={filterLanguage}
                                        onChange={(e) => setFilterLanguage(e.target.value)}
                                    />
                                </div>
                            </section>
                        </aside>
                    ) : (
                        <div className="filters-toggle" style={{ marginBottom: '1rem' }}>
                            <button className="btn" onClick={toggleFilters}>
                                Show Filters 👀
                            </button>
                        </div>
                    )}
                    <main className="ws-main-content">
                        <section className="ws-therapist-list">
                            <h2>Therapists</h2>
                            {filteredTherapists.length > 0 ? (
                                filteredTherapists.map((t) => (
                                    <div
                                        key={t._id}
                                        className="ws-therapist-card"
                                        onClick={() => handleTherapistClick(t)}
                                    >
                                        <img
                                            src={t.image || '/images/placeholder-avatar.png'}
                                            alt="Therapist Avatar"
                                            className="ws-therapist-avatar"
                                        />
                                        <div className="ws-therapist-info">
                                            <h4 className="ws-therapist-name">{t.displayName}</h4>
                                            <div className="ws-therapist-badges">
                                                {t.therapistApproved ? (
                                                    <span className="ws-approval-badge">Approved</span>
                                                ) : (
                                                    <span className="ws-pending-badge">Pending</span>
                                                )}
                                            </div>
                                            <div className="ws-therapist-rating">
                                                <span className="ws-avg-label">Avg Rating:</span>
                                                <div className="ws-rating-stars">
                                                    {[1, 2, 3, 4, 5].map((star) => {
                                                        const starColor =
                                                            star <= Math.round(t.averageRating) ? '#ffc107' : '#ccc';
                                                        return (
                                                            <svg
                                                                key={star}
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 24 24"
                                                                fill={starColor}
                                                                style={{ marginRight: '2px' }}
                                                            >
                                                                <path d="M12 .587l3.668 7.431L24 9.75l-6 5.848 1.417 8.268L12 18.896 4.583 23.866 6 15.598 0 9.75l8.332-1.732z" />
                                                            </svg>
                                                        );
                                                    })}
                                                </div>
                                                <span className="ws-avg-value">
                                                    {t.averageRating?.toFixed(1) || '0.0'} / 5
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No therapists found matching your criteria.</p>
                            )}
                        </section>
                    </main>
                </div>
            )}

            {activeTab === "bookings" && (
                <section className="ws-mybookings">
                    <h3>
                        My Upcoming Sessions <span className="emoji-bell">🔔</span>
                    </h3>
                    {myBookings.length === 0 && <p>No upcoming sessions found.</p>}
                    {myBookings.map((booking) => (
                        <div key={booking._id} className="my-booking-card">
                            <p>
                                <strong>Time:</strong> {booking.time}
                            </p>
                            {booking.therapistId && (
                                <p>
                                    <strong>Therapist:</strong> {booking.therapistId.displayName || 'Unknown'}
                                </p>
                            )}
                            <p>
                                <strong>Status:</strong> {booking.status}
                            </p>
                            {booking.status === 'upcoming' && (
                                <div className="ws-button-group">
                                    {/* Instead of a join button, show the following message */}
                                    {booking.meetingToken && (
                                        <p>Meeting link will be mail to you</p>
                                    )}
                                    <button className="ws-cancel-button" onClick={() => cancelBooking(booking._id)}>
                                        Cancel ❌
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </section>
            )}

            {activeTab === "profile" && (
                <section className="ws-profile-section">
                    <h2>My Profile 👤</h2>
                    <label>Description:</label>
                    <div className="ws-profile-description">
                        {editingProfile ? (
                            <textarea
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        ) : (
                            <p>{description || 'No description provided.'}</p>
                        )}
                        <button
                            className="ws-edit-icon"
                            onClick={() => setEditingProfile(!editingProfile)}
                            title="Edit Description"
                        >
                            {editingProfile ? '✔️' : '✏️'}
                        </button>
                    </div>
                    {user && (
                        <>
                            <p>
                                <strong>Name:</strong> {user.displayName}
                            </p>
                            <p>
                                <strong>Email:</strong> {user.email}
                            </p>
                        </>
                    )}
                    <button className="ws-submit-rating" onClick={handleProfileUpdate}>
                        Update Profile ✅
                    </button>
                </section>
            )}

            {/* Therapist Detail Modal */}
            {selectedTherapist && (
                <div className="ws-modal-overlay" onClick={closeModal}>
                    <div className="ws-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="ws-modal-close" onClick={closeModal}>
                            &times;
                        </button>
                        <h2>{selectedTherapist.displayName}</h2>
                        <p>
                            <strong>Description:</strong> {selectedTherapist.description || 'N/A'}
                        </p>
                        <p>
                            <strong>Communication Methods:</strong> {selectedTherapist.communicationMethods?.join(', ') || 'N/A'}
                        </p>
                        {selectedTherapist.communicationMethods?.includes('Inperson') && (
                            <p>
                                <strong>Address:</strong> {selectedTherapist.address || 'N/A'}
                            </p>
                        )}
                        <p>
                            <strong>Languages:</strong> {selectedTherapist.languages?.join(', ') || 'N/A'}
                        </p>
                        <p>
                            <strong>Specializations:</strong> {selectedTherapist.specializations?.join(', ') || 'N/A'}
                        </p>
                        <p>
                            <strong>Average Rating:</strong> {selectedTherapist.averageRating?.toFixed(1) || 0} / 5
                        </p>
                        <h3>Availability</h3>
                        {selectedTherapist.availability?.length > 0 ? (
                            <ul className="ws-availability-list">
                                {selectedTherapist.availability.map((slot) => {
                                    const mine = isSlotMine(slot);
                                    return (
                                        <li
                                            key={slot._id}
                                            className={
                                                slot.isBooked
                                                    ? 'ws-slot-item booked-slot'
                                                    : selectedSlot === slot
                                                        ? 'ws-slot-item selected'
                                                        : 'ws-slot-item'
                                            }
                                            onClick={() => {
                                                if (!slot.isBooked) {
                                                    setSelectedSlot(slot);
                                                } else {
                                                    if (mine) {
                                                        alert('This slot is already booked by YOU.');
                                                    } else {
                                                        alert('This slot is booked by someone else.');
                                                    }
                                                }
                                            }}
                                        >
                                            <span className="ws-day">{slot.day}</span>
                                            <span className="ws-time">
                                                {slot.startTime} - {slot.endTime}
                                            </span>
                                            {slot.isBooked && (
                                                <>
                                                    {mine ? (
                                                        <span style={{ color: '#2e7d32', marginLeft: '0.5rem' }}>
                                                            (Booked by You)
                                                        </span>
                                                    ) : (
                                                        <span style={{ color: '#d32f2f', marginLeft: '0.5rem' }}>
                                                            (Already Booked)
                                                        </span>
                                                    )}
                                                    {mine && slot.meetingToken && (
                                                        <div className="ws-button-group" style={{ marginTop: '0.5rem' }}>
                                                            <p>Meeting link will be mail to you</p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <p>No available slots.</p>
                        )}
                        {selectedSlot && !selectedSlot.isBooked && (
                            <div className="ws-button-group ws-booking-actions">
                                <p>
                                    Selected Slot: {selectedSlot.day}, {selectedSlot.startTime} - {selectedSlot.endTime}
                                </p>
                                <button className="ws-book-button" onClick={bookTherapist}>
                                    Book Now 📅
                                </button>
                            </div>
                        )}
                        <div className="ws-rating-section">
                            <h3>Rate {selectedTherapist.displayName}</h3>
                            <div className="ws-stars">
                                {[1, 2, 3, 4, 5].map((star) => {
                                    const ratingValue = star;
                                    return (
                                        <label key={star}>
                                            <input
                                                type="radio"
                                                name="rating"
                                                value={ratingValue}
                                                style={{ display: 'none' }}
                                                onClick={() => setRating(ratingValue)}
                                            />
                                            <FaStar
                                                className="ws-star"
                                                size={24}
                                                color={ratingValue <= (hoverRating || rating) ? '#ffc107' : '#ccc'}
                                                onMouseEnter={() => setHoverRating(ratingValue)}
                                                onMouseLeave={() => setHoverRating(0)}
                                            />
                                        </label>
                                    );
                                })}
                            </div>
                            <button className="ws-submit-rating" onClick={submitRating}>
                                Submit Rating ⭐
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
