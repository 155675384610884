import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import "../../styles/assessment.css"; // Same stylesheet as your other tests

const DisordersTest = () => {
    const [name, setName] = useState("");
    const [answers, setAnswers] = useState({});
    const [completed, setCompleted] = useState(false);
    const [nameSubmitted, setNameSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const resultRef = useRef(null);
    const questions = [
        // 1) Narcissistic Personality Disorder (NPD)
        {
            id: 1,
            text: "Do you often feel you deserve special attention or admiration?",
            category: "Narcissistic Personality Disorder (NPD)",
        },
        {
            id: 2,
            text: "Do you exaggerate your achievements to impress others?",
            category: "Narcissistic Personality Disorder (NPD)",
        },

        // 2) Antisocial Personality Disorder
        {
            id: 3,
            text: "Do you disregard rules or laws without feeling guilty?",
            category: "Antisocial Personality Disorder",
        },
        {
            id: 4,
            text: "Have you manipulated others purely for personal gain?",
            category: "Antisocial Personality Disorder",
        },

        // 3) Borderline Personality Disorder (BPD)
        {
            id: 5,
            text: "Do you have sudden mood changes or extreme emotional reactions?",
            category: "Borderline Personality Disorder (BPD)",
        },
        {
            id: 6,
            text: "Do you fear being abandoned by those close to you?",
            category: "Borderline Personality Disorder (BPD)",
        },

        // 4) Histrionic Personality Disorder
        {
            id: 7,
            text: "Do you constantly seek to be the center of attention?",
            category: "Histrionic Personality Disorder",
        },
        {
            id: 8,
            text: "Do you often use your appearance to get attention?",
            category: "Histrionic Personality Disorder",
        },

        // 5) Paranoid Personality Disorder
        {
            id: 9,
            text: "Do you often suspect others might harm or deceive you?",
            category: "Paranoid Personality Disorder",
        },
        {
            id: 10,
            text: "Do you struggle to trust even close friends or family?",
            category: "Paranoid Personality Disorder",
        },

        // 6) Schizoid Personality Disorder
        {
            id: 11,
            text: "Do you prefer being alone and rarely want close relationships?",
            category: "Schizoid Personality Disorder",
        },
        {
            id: 12,
            text: "Do you usually feel detached or indifferent toward others?",
            category: "Schizoid Personality Disorder",
        },

        // 7) Schizotypal Personality Disorder
        {
            id: 13,
            text: "Have people described your thoughts or behavior as odd or eccentric?",
            category: "Schizotypal Personality Disorder",
        },
        {
            id: 14,
            text: "Do you sometimes sense odd experiences, like seeing or hearing things not there?",
            category: "Schizotypal Personality Disorder",
        },

        // 8) Obsessive-Compulsive Disorder (OCD)
        {
            id: 15,
            text: "Do you perform specific rituals (checking, cleaning) to reduce anxiety?",
            category: "Obsessive-Compulsive Disorder (OCD)",
        },
        {
            id: 16,
            text: "Do repetitive thoughts or worries frequently disrupt your daily life?",
            category: "Obsessive-Compulsive Disorder (OCD)",
        },

        // 9) Generalized Anxiety Disorder (GAD)
        {
            id: 17,
            text: "Do you worry excessively about many aspects of daily life?",
            category: "Generalized Anxiety Disorder (GAD)",
        },
        {
            id: 18,
            text: "Do you often feel restless or have trouble sleeping due to worries?",
            category: "Generalized Anxiety Disorder (GAD)",
        },

        // 10) Social Anxiety Disorder
        {
            id: 19,
            text: "Do you avoid social events due to fear of being judged or embarrassed?",
            category: "Social Anxiety Disorder",
        },
        {
            id: 20,
            text: "Do you feel intense anxiety speaking or performing in front of others?",
            category: "Social Anxiety Disorder",
        },

        // 11) Panic Disorder
        {
            id: 21,
            text: "Have you had sudden panic attacks with intense fear or physical symptoms?",
            category: "Panic Disorder",
        },
        {
            id: 22,
            text: "Do you worry about having another panic attack?",
            category: "Panic Disorder",
        },

        // 12) Major Depressive Disorder (MDD)
        {
            id: 23,
            text: "Do you frequently feel deep sadness or lose interest in activities?",
            category: "Major Depressive Disorder (MDD)",
        },
        {
            id: 24,
            text: "Have your sleep or appetite changed significantly due to low mood?",
            category: "Major Depressive Disorder (MDD)",
        },

        // 13) Bipolar I Disorder
        {
            id: 25,
            text: "Have you experienced a week or more of unusually high or irritable mood?",
            category: "Bipolar I Disorder",
        },
        {
            id: 26,
            text: "During that high mood, did you take risks (e.g., big spending) without caution?",
            category: "Bipolar I Disorder",
        },

        // 14) Bipolar II Disorder
        {
            id: 27,
            text: "Do you have short bursts of elevated mood along with longer low periods?",
            category: "Bipolar II Disorder",
        },
        {
            id: 28,
            text: "Do these low periods include symptoms like major depression?",
            category: "Bipolar II Disorder",
        },

        // 15) Persistent Depressive Disorder (Dysthymia)
        {
            id: 29,
            text: "Have you felt a low mood or sadness for many months or years?",
            category: "Persistent Depressive Disorder (Dysthymia)",
        },
        {
            id: 30,
            text: "Do you often feel hopeless or have low self-esteem for a long stretch?",
            category: "Persistent Depressive Disorder (Dysthymia)",
        },

        // 16) Post-Traumatic Stress Disorder (PTSD)
        {
            id: 31,
            text: "Have you faced a traumatic event that still causes disturbing memories?",
            category: "Post-Traumatic Stress Disorder (PTSD)",
        },
        {
            id: 32,
            text: "Do you avoid reminders or feel overly on guard after a trauma?",
            category: "Post-Traumatic Stress Disorder (PTSD)",
        },

        // 17) Eating Disorders
        {
            id: 33,
            text: "Are you overly concerned about weight or body shape?",
            category: "Eating Disorders",
        },
        {
            id: 34,
            text: "Have you had episodes of binge eating or purging behaviors?",
            category: "Eating Disorders",
        },

        // 18) Schizophrenia
        {
            id: 35,
            text: "Have you had hallucinations or false beliefs that affect daily life?",
            category: "Schizophrenia",
        },
        {
            id: 36,
            text: "Do you experience disorganized thoughts or speech regularly?",
            category: "Schizophrenia",
        },

        // 19) Dissociative Identity Disorder (DID)
        {
            id: 37,
            text: "Do you sometimes feel multiple distinct identities or personalities within you?",
            category: "Dissociative Identity Disorder (DID)",
        },
        {
            id: 38,
            text: "Have you had memory gaps for personal details or daily events?",
            category: "Dissociative Identity Disorder (DID)",
        },

        // 20) ADHD
        {
            id: 39,
            text: "Do you find it hard to stay focused or often lose track of details?",
            category: "Attention-Deficit/Hyperactivity Disorder (ADHD)",
        },
        {
            id: 40,
            text: "Are you restless or impulsive, struggling to stay still or wait?",
            category: "Attention-Deficit/Hyperactivity Disorder (ADHD)",
        },
    ];

    const options = ["Never", "Rarely", "Sometimes", "Often", "Always"];

    const handleAnswerChange = (questionId, value) => {
        setAnswers((prev) => ({ ...prev, [questionId]: value }));
        setError(false);
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert("Please enter a valid name to begin.");
            return;
        }
        setNameSubmitted(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(answers).length < questions.length) {
            setError(true);
            alert("Please answer all questions before submitting.");
            return;
        }
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            setCompleted(true);
        }, 800);
    };

    const calculateResult = () => {
        const categoryScores = {};
        const categoryCounts = {};

        questions.forEach((q) => {
            const val = parseInt(answers[q.id] || 0);
            categoryScores[q.category] = (categoryScores[q.category] || 0) + val;
            categoryCounts[q.category] = (categoryCounts[q.category] || 0) + 1;
        });

        const normalizedScores = {};
        Object.keys(categoryScores).forEach((cat) => {
            const maxScore = categoryCounts[cat] * (options.length - 1);
            normalizedScores[cat] = parseFloat(((categoryScores[cat] / maxScore) * 100).toFixed(1));
        });

        return normalizedScores;
    };

    const captureScreenshot = async () => {
        if (resultRef.current) {
            try {
                const canvas = await html2canvas(resultRef.current);
                const imageDataUrl = canvas.toDataURL("image/png");
                if (navigator.share) {
                    const blob = await (await fetch(imageDataUrl)).blob();
                    const file = new File([blob], "disorders-test-results.png", { type: "image/png" });
                    await navigator.share({
                        title: "My Disorders Test Results",
                        text: "Check out my mental health assessment results!",
                        files: [file],
                    });
                } else {
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    link.download = "disorders-test-results.png";
                    link.click();
                }
            } catch (err) {
                console.error("Error capturing screenshot:", err);
                alert("Failed to share or download results. Please try again.");
            }
        }
    };

    const renderResults = () => {
        const results = calculateResult();
        const highestCategory = Object.keys(results).reduce((a, b) =>
            results[a] > results[b] ? a : b
        );

        return (
            <div className="result" ref={resultRef}>
                <h2>Assessment Results for {name}</h2>
                <div className="dominant-result">
                    <h3>Your Highest Score:</h3>
                    <p>
                        <strong>{highestCategory}</strong> ({results[highestCategory]}%)
                    </p>
                </div>
                {Object.keys(results).map((cat) => {
                    const score = results[cat];
                    const isHighest = cat === highestCategory;
                    const barColor = isHighest ? "#FF5722" : score > 70 ? "#F44336" : score > 40 ? "#FFC107" : "#4CAF50";

                    return (
                        <div className="result-item" key={cat}>
                            <div className="category" style={{ fontWeight: isHighest ? "bold" : "normal" }}>
                                {cat}
                            </div>
                            <div className="result-progress-bar">
                                <div
                                    className="result-progress-fill"
                                    style={{
                                        width: `${score}%`,
                                        backgroundColor: barColor,
                                    }}
                                ></div>
                            </div>
                            <p style={{ fontWeight: isHighest ? "bold" : "normal" }}>{score}%</p>
                        </div>
                    );
                })}
                <button className="button" onClick={() => navigate("/")}>Back to Home</button>
                <button className="button" onClick={captureScreenshot}>Share Results</button>
            </div>
        );
    };

    return (
        <div className="assessment-container">
            <h1 className="assessment-header">Disorders Test</h1>
            {!nameSubmitted ? (
                <form onSubmit={handleNameSubmit} className="name-form">
                    <h2>Please enter your name to begin:</h2>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">Start Test</button>
                </form>
            ) : !completed ? (
                <>
                    {error && <p className="error">Please answer all questions before submitting!</p>}
                    <div className="vertical-progress-bar">
                        <div className="progress-fill" style={{ height: `${Math.round((Object.keys(answers).length / questions.length) * 100)}%` }}>
                            {Math.round((Object.keys(answers).length / questions.length) * 100)}%
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="assessment-form">
                        {questions.map((q) => (
                            <div key={q.id} className="question">
                                <p>{q.text}</p>
                                <div className="options">
                                    {options.map((option, idx) => (
                                        <label key={idx} className="option-label" aria-label={`Option ${option} for question ${q.id}`}>
                                            <input
                                                type="radio"
                                                name={`question-${q.id}`}
                                                value={idx}
                                                onChange={() => handleAnswerChange(q.id, idx)}
                                                required
                                            />
                                            <span>{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <button className="button" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit Test"}
                        </button>
                    </form>
                </>
            ) : (
                renderResults()
            )}
        </div>
    );
};

export default DisordersTest;