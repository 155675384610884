import React, { useState } from "react";
import axios from "axios";

const ChatAI = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");

    const sendMessage = async () => {
        if (!input.trim()) return;

        // Add user message to UI
        const newMessages = [...messages, { sender: "user", text: input }];
        setMessages(newMessages);
        setInput("");

        // Send request to backend AI API
        try {
            const response = await axios.post("http://localhost:5001/chat", { message: input });
            setMessages([...newMessages, { sender: "ai", text: response.data.response }]);
        } catch (error) {
            console.error("Error communicating with AI:", error);
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h2>AI Therapy Chatbot</h2>
            <div style={{ height: "300px", overflowY: "scroll", border: "1px solid #ccc", padding: "10px" }}>
                {messages.map((msg, index) => (
                    <p key={index} style={{ color: msg.sender === "user" ? "blue" : "green" }}>
                        <strong>{msg.sender === "user" ? "You" : "AI"}:</strong> {msg.text}
                    </p>
                ))}
            </div>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default ChatAI;
